import { Table, Typography, ConfigProvider, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import {
    Row,
    Modal,
    Card,
    Button,
    Col,
    Popover,
    Divider
} from "antd"

import { controller } from "../controller";

import arrowLeft from '../../assets/icons/arrow-circle-left.svg';
import arrowRight from '../../assets/icons/arrow-circle-right.svg';

import './style.css'



const { Text } = Typography;


const TreatmentPlanTable = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [showModalID, setShowModalID] = useState(false)
    const [data, setData] = useState([])
    const [edulist, setEduList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [hasDiscount, setHasDiscount] = useState(false);




    const contentExists = edulist && edulist.educational_content && edulist.educational_content.length > 0 && edulist.educational_content.some(item => item.video_file && item.video_file.length > 0);

    // Get the total number of video files if educational_content exists
    const totalPages = contentExists
        ? edulist.educational_content.reduce((total, item) => total + (item.video_file ? item.video_file.length : 0), 0)
        : 0;


    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Handle "Previous" button click
    const handlePrevious = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleShowModal = (record) => {
        console.log(record.key)
        setShowModalID(record.key)
        setShowModal(true)
    }

    const handleEduList = async () => {
        var url = window.location.href;
        const urlParams = new URLSearchParams(url.slice(url.indexOf('?')));
        const patientId = urlParams.get('patient_id');
        try {
            const response = await controller.getTreatmentPlanDetail(showModalID, patientId);

            if (response.status < 250) {
                const data = await response.json;
                setEduList(data); // Set state with fetched data
            } else {
                console.log("Response status not OK:", response.status);
            }
        } catch (e) {
            console.error("Error fetching data:", e);
        }
    };

    useEffect(() => {
        if (showModalID) {
            handleEduList()
        }
    }, [showModalID])

    useEffect(() => {
        if (props.data) {
            // Flatten the data to include each treatment plan as a separate row
            const flattenedData = props.data.flatMap(item =>
                item.treatment_plans.map(tp => ({
                    key: tp.id,
                    patient: item.patient,
                    note: item.note,
                    status: item.status,
                    created_date: item.created_date,
                    updated_date: item.updated_date,
                    name: tp.name,
                    tooth: tp.tooth_from,
                    estimated_cost: tp.estimated_cost,
                    priority: tp.priority,
                    insurance_coverage: tp.insurance_coverage,
                    discount: tp.discount || null, // Set null if no discount
                    patient_responsibility: tp.patient_responsibility || '-',
                    video_link: tp.video_link,
                    treatment_procedures: tp.treatment_procedures,
                }))
            );

            setData(flattenedData);

            // Check if there is any discount in the flattened data
            const discountExists = flattenedData.some(item => item.discount !== null);
            setHasDiscount(discountExists);
        }
    }, [props.data]);



    const rowClassName = (record, index) => {
        return index === 0 ? 'hide-first-row' : '';
    };




    const columns = [
        {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority',
            sorter: (a, b) => a.priority - b.priority,
            defaultSortOrder: 'ascend',
            width: '5%',
            render: (priority) => (
                <Row justify={"center"} align="bottom">
                    {priority !== null ? priority : "-"}
                </Row>
            ),
        },
        {
            title: 'Treatment',
            dataIndex: 'name',
            key: 'name',
            width: '15%',
            render: (name) => (
                <Row justify={name ? "" : "center"} align="bottom">
                    {name ? name : "-"}
                </Row>
            ),
        },
        {
            title: 'Tooth / Quadrant',
            dataIndex: 'tooth',
            key: 'tooth',
            width: '10%',
            render: (tooth) => (
                <Row justify={tooth ? "" : "center"} align="bottom">
                    {tooth ? tooth : "-"}
                </Row>
            ),
        },
        {
            title: "Estimated Cost",
            dataIndex: "estimated_cost",
            key: "estimated_cost",
            width: '17.5%',
            ellipsis: true,
            render: (estimated_cost) => (
                <Row justify={estimated_cost ? "" : "center"} align="bottom">
                    ${estimated_cost ? parseFloat(estimated_cost).toFixed(2) : "-"}
                </Row>
            )
        },
        {
            title: "Insurance Coverage",
            dataIndex: "insurance_coverage",
            key: "insurance_coverage",
            width: '17.5%',
            ellipsis: true,
            render: (insurance_coverage) => (
                <Row justify={insurance_coverage ? "" : "center"} align="bottom">
                    ${insurance_coverage ? insurance_coverage : "-"}
                </Row>
            )
        },
        {
            title: "Your Responsibility",
            dataIndex: "patient_responsibility",
            key: "patient_responsibility",
            width: '17.5%',
            ellipsis: true,
            render: (patient_responsibility) => (
                <Row justify={patient_responsibility ? "" : "center"} align="bottom">
                    ${patient_responsibility ? patient_responsibility : "-"}
                </Row>
            )
        },
        ...(hasDiscount ? [{
            title: "Discount",
            dataIndex: "discount",
            key: "discount",
            width: '17.5%',
            ellipsis: true,
            render: (discount) => (
                <Row align="bottom">
                    %{discount || "0"}
                </Row>
            ),
        }] : []),
        {
            title: 'Patient Education',
            dataIndex: 'video_link',
            key: 'video_link',
            width: '10%',
            ellipsis: true,
            render: (video_link, record) => (
                <Row justify={video_link ? "" : "center"} align="bottom" >
                    <Popover
                        showArrow={false}
                        placement='bottomRight'
                        content={() => (
                            <div className='card-in-modal'>
                                <div className='insideCardModal'>

                                    {edulist && edulist.educational_content && edulist.educational_content.length > 0 ? (
                                        <div>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <p style={{ fontSize: 16, marginTop: 25 }}>Treatment Description</p>

                                            </div>
                                            <div style={{ marginBottom: '20px' }}>

                                                <p style={{ fontSize: 12, color: '#848696' }}>
                                                    {edulist.educational_content &&
                                                        edulist.educational_content[0] &&
                                                        edulist.educational_content[0].description ?
                                                        edulist.educational_content[0].description :
                                                        'No description available'}
                                                </p>
                                            </div>
                                            <div style={{ marginBottom: 15, display: 'flex', flexDirection: 'row' }}>
                                                <p style={{ fontSize: 16 }}>Educational Content</p>

                                            </div>
                                            <Card>
                                                {
                                                    edulist.educational_content[0] &&
                                                        edulist.educational_content[0].video_file &&
                                                        edulist.educational_content[0].video_file[currentPage] && // Ensure currentPage index exists
                                                        edulist.educational_content[0].video_file[currentPage].link ? (
                                                        edulist.educational_content[0].video_file[currentPage].link.includes('youtube.com') ||
                                                            edulist.educational_content[0].video_file[currentPage].link.includes('spearedu.co') ||
                                                            edulist.educational_content[0].video_file[currentPage].link.includes('patient-api.speareducation.com') ? (
                                                            <iframe
                                                                width="100%"
                                                                height="246px"
                                                                src={edulist.educational_content[0].video_file[currentPage].link.replace("watch?v=", "embed/")}
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen
                                                                title="Educational Video"
                                                            ></iframe>
                                                        ) : (
                                                            <video width="100%" height="246px" controls>
                                                                <source
                                                                    src={edulist.educational_content[0].video_file[currentPage].link || edulist.educational_content[0].video_file[currentPage].video}
                                                                    type="video/mp4"
                                                                />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        )
                                                    ) : (
                                                        <video width="100%" height="246px" controls>
                                                            <source
                                                                src={edulist.educational_content[0].video_file[currentPage].link || edulist.educational_content[0].video_file[currentPage].video}
                                                                type="video/mp4"
                                                            />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    )
                                                }
                                            </Card>

                                            <Row justify="center" style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
                                                <Button
                                                    type="text"
                                                    icon={<img src={arrowLeft} alt="Open" />}
                                                    style={{ marginTop: 25 }}
                                                    disabled={currentPage === 0}
                                                    onClick={handlePrevious}
                                                />
                                                <span style={{ margin: '0 10px', lineHeight: '1.5', marginTop: 25 }}> {currentPage + 1} </span>
                                                <Button
                                                    type="text"
                                                    icon={<img src={arrowRight} alt="Open" />}
                                                    style={{ marginTop: 25 }}
                                                    disabled={currentPage === totalPages - 1}
                                                    onClick={handleNext}
                                                />
                                            </Row>
                                        </div>

                                    ) : (
                                        <>
                                            <p>No educational content available</p>

                                            {/* <Row justify='end' style={{ marginTop: 35 }}>
                                            <Button type="primary" onClick={() => { updateEducational(); }}>
                                                Done
                                            </Button>
                                        </Row> */}
                                        </>
                                    )}
                                </div>
                            </div>
                        )} trigger="click">
                        <Text onClick={() => { handleShowModal(record) }} underline style={{ color: '#6B43B5', textDecoration: "underline", cursor: "pointer" }}>Learn More</Text>
                    </Popover>
                </Row>
            ),
        },
    ].filter(Boolean);






    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: "#983cfc",
                    controlItemBgHover: "#c293ff",
                    colorLink: '#983cfc',
                },
                components: {
                    Table: {
                        borderRadius: "8px",
                        borderColor: "#eee",
                    },
                },
            }}
        >
            <Table
                dataSource={data}
                columns={columns}
                // bordered
                pagination={false}
                rowClassName={rowClassName}
                scroll={{ x: 'max-content' }}
                expandable={{
                    expandedRowRender: (record) => {

                        // Find the treatment plan
                        const treatmentPlan = props.data[0] && props.data[0].treatment_plans && props.data[0].treatment_plans.find(
                            (plan) => plan.id === record.key
                        );

                        if (!treatmentPlan) {
                            return <p>No treatment plans available</p>;
                        }

                        if (!treatmentPlan.treatment_procedures || treatmentPlan.treatment_procedures.length === 0) {
                            return <p>No treatment found</p>;
                        }

                        const expandedData = treatmentPlan.treatment_procedures.map((procedure, index) => ({
                            key: index,
                            description: (
                                <Tooltip title={`${procedure.procedure_code.procedure_code || '-'} - ${procedure.procedure_code.procedure_code_description || '-'}`} placement="top" >
                                    <div style={{
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 2,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '100%',
                                        whiteSpace: 'normal',
                                        alignItems: 'center',
                                        marginTop: 10
                                    }}>
                                        {`${procedure.procedure_code.procedure_code || '-'} - ${procedure.procedure_code.procedure_code_description || '-'}`}
                                    </div>
                                </Tooltip>
                            ),
                            tooth: procedure.tooth || '-',
                            estimated_cost: procedure.estimated_cost ? `$${procedure.estimated_cost}` : '-',
                            insurance_estimate: procedure.insurance_estimate ? `$${procedure.insurance_estimate}` : '-',
                            patient_responsibility: procedure.patient_responsibility ? `$${procedure.patient_responsibility}` : '-',
                            discount: procedure.discount && procedure.discount !== "0.00" && procedure.discount !== "0" ? `%${procedure.discount}` : '%0',
                        }));


                        const isLargeScreen = window.innerWidth >= 1900; // Adjust width for large screens
                        const isLaptopSize = window.innerWidth >= 992 && window.innerWidth < 1900;
                        const isMobileSize = window.innerWidth <= 768;


                        


                        const expandedColumns = [
                            {
                                title: 'Priority',
                                dataIndex: 'priority',
                                key: 'priority',
                                width: isLargeScreen ? (hasDiscount ? '6%' : '10%') : isLaptopSize ? (hasDiscount ? '6%' : '8%') : isMobileSize ? (hasDiscount ? '6%' : ('8%')) : '12%',
                                // ...other properties
                            },
                            {
                                title: 'Treatment',
                                dataIndex: 'description',
                                key: 'description',
                                width: isLargeScreen ? (hasDiscount ? "13%" : "15%") : isLaptopSize ? (hasDiscount ? "14%" : "16%") : isMobileSize ? (hasDiscount ? '6%' : ('16%')) : '20%',
                                // ...other properties
                            },
                            {
                                title: 'Tooth / Quadrant',
                                dataIndex: 'tooth',
                                key: 'tooth',
                                width: isLargeScreen ? (hasDiscount ? "9%" : "10%") : isLaptopSize ? (hasDiscount ? "8.4%" : "10%") : isMobileSize ? (hasDiscount ? '6%' : ('10%')) : '15%',
                                // ...other properties
                            },
                            {
                                title: 'Estimated Cost',
                                dataIndex: 'estimated_cost',
                                key: 'estimated_cost',
                                width: isLargeScreen ? (hasDiscount ? "15.5%" : '18%') : isLaptopSize ? (hasDiscount ? "14.8%" : '18%') : isMobileSize ? (hasDiscount ? '6%' : ('16%')) : '25%',
                                // ...other properties
                            },
                            {
                                title: 'Insurance Coverage',
                                dataIndex: 'insurance_estimate',
                                key: 'insurance_estimate',
                                width: isLargeScreen ? (hasDiscount ? "16.5%" : "18%") : isLaptopSize ? (hasDiscount ? "16.2%" : "18.3%") : isMobileSize ? (hasDiscount ? '6%' : ('18%')) : '23%',
                                // ...other properties
                            },
                            {
                                title: 'Your Responsibility',
                                dataIndex: 'patient_responsibility',
                                key: 'patient_responsibility',
                                width: isLargeScreen ? '15.5%' : isLaptopSize ? '16%' : '20%',
                                // ...other properties
                            },
                            ...(hasDiscount ? [{
                                title: "Discount",
                                dataIndex: "discount",
                                key: "discount",
                                width: isLargeScreen ? '17.5%' : isLaptopSize ? '18%' : '20%',
                            }] : []),
                            {
                                title: 'Patient Education',
                                dataIndex: 'video_link',
                                key: 'video_link',
                                width: isLargeScreen ? '13%' : isLaptopSize ? '14%' : '15%',
                                // ...other properties
                            },
                        ];







                        return (
                            <Table
                                // style={{marginLeft: '100px'}}
                                columns={expandedColumns}
                                dataSource={expandedData}
                                className="nested-table-no-header"
                                pagination={false}
                                // bordered
                                showHeader={false}
                            />
                        );
                    },
                }}
            />


        </ConfigProvider>

    );
}

export default TreatmentPlanTable;
